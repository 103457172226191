import React from 'react'
import { useState } from 'react'
import './Navbar.css'
import logo from '../../Assets/logo.svg'
import underline from '../../Assets/nav_underline.svg'
import AnchorLink from 'react-anchor-link-smooth-scroll'

const Navbar = () => {

  const [menu,setMenu] = useState("home");

  return (
    <div className='navbar'>
      <div class="image-container">
        <img src={logo} alt="" />
      </div>

        <ul className="Nav-Menu">
            <li><AnchorLink className='anchor-link' href='#home'><p onMouseOverCapture={()=> setMenu("home")}>Home</p></AnchorLink>{menu==="home"?<img src={underline} alt='' />:<></>}</li>
            <li><AnchorLink className='anchor-link' offset={50} href='#about'><p onMouseOverCapture={()=> setMenu("about")}>About Me</p></AnchorLink>{menu==="about"?<img src={underline} alt='' />:<></>}</li>
            <li><AnchorLink className='anchor-link' offset={50} href='#services'><p onMouseOverCapture={()=> setMenu("services")}>Services</p></AnchorLink>{menu==="services"?<img src={underline} alt='' />:<></>}</li>
            <li><AnchorLink className='anchor-link' offset={50} href='#portfolio'><p onMouseOverCapture={()=> setMenu("portfolio")}>Portfolio</p></AnchorLink>{menu==="portfolio"?<img src={underline} alt='' />:<></>}</li>
            <li><a className='anchor-link' href='https://drive.google.com/file/d/1ifZZtCOmA6omU9eVnOk5mhjQyZElpmDm/view?usp=sharing' target="_blank" rel="noopener noreferrer"><p onMouseOverCapture={()=> setMenu("resume")}>Resume</p></a>{menu==="resume"?<img src={underline} alt='' />:<></>}</li>
        </ul>
        <div className="nav-connect"><AnchorLink className='anchor-link' offset={50} href='#contact'>Connect With Me</AnchorLink></div>
    </div>
  )
}

export default Navbar