import React from 'react'
import Navbar from './Components/NavBar/Navbar'
import Header from './Components/Header/Header'
import About from './Components/About/About'
import Services from './Components/Services/Services'
import MyWork from './Components/MyWork/MyWork'
import Resume from './Components/Resume/Resume'
import Footer from './Components/Footer/Footer'
import { Analytics } from "@vercel/analytics/react"
import MovieRecommender from './Components/Movie-Recommender/MovieRecommender'

const App = () => {
  return (
    <div>
      <Navbar/>
      <Header/>
      <About/>
      <Services/>
      <MyWork/>
      <Resume/>
      <MovieRecommender/>
      <Footer/>
      <Analytics />
    </div>
  )
}

export default App