import React, { useState, useEffect } from 'react';
import axios from 'axios';

const MovieRecommender = () => {
  const [selectedMovie, setSelectedMovie] = useState('');
  const [recommendations, setRecommendations] = useState([]);

  useEffect(() => {
    if (selectedMovie) {
      axios.get(`http://localhost:5000/recommend?movie=${selectedMovie}`)
        .then(response => {
          setRecommendations(response.data.recommendations);
        })
        .catch(error => console.error('Error fetching recommendations:', error));
    }
  }, [selectedMovie]);

  return (
    <div>
      <select onChange={(e) => setSelectedMovie(e.target.value)}>
        {/* Populate this with your movie titles */}
      </select>
      <div>
        {recommendations.map((movie, index) => (
          <div key={index}>{movie}</div>
        ))}
      </div>
    </div>
  );
};

export default MovieRecommender;